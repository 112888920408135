import React from "react";

export default function About() {
  return (
    <div className="about">
      <h3 className="about--title">About</h3>
      <p className="about--paragraph">
        I am an IT Professional and Personal Finance Coach.
      </p>
    </div>
  );
}
